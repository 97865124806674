import React, { useState, useEffect, useCallback } from 'react';
import EnhancedTextArea from './EnhancedTextArea';
import { useAssistant } from '../contexts/AssistantContext';

const ProblemStatementBuilder = ({ initialStatement, onProblemStatementChange }) => {
  const [problemStatement, setProblemStatement] = useState({
    full_statement: '',
    short_description: '',
    what: '',
    where: '',
    when: '',
    who: '',
    how: '',
    why: ''
  });
  const [isLoading, setIsLoading] = useState(true);
  const { addAssistantMessage } = useAssistant();

  useEffect(() => {
    if (initialStatement) {
      setProblemStatement(prevState => ({
        ...prevState,
        ...initialStatement
      }));
    }
    setIsLoading(false);
  }, [initialStatement]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProblemStatement(prev => ({ ...prev, [name]: value }));
  };

  const handleAIComment = (comment) => {
    addAssistantMessage(comment);
  };

  const generateFullStatement = useCallback(() => {
    const { what, where, when, who, how, why } = problemStatement;
    const generatedStatement = `The problem is ${what} occurring at ${where}. It was first noticed on ${when} and affects ${who}. The issue manifests by ${how}. This is significant because ${why}.`;
    setProblemStatement(prev => ({ ...prev, full_statement: generatedStatement }));
  }, [problemStatement]);

  const handleGenerateStatement = (e) => {
    e.preventDefault();
    generateFullStatement();
  };

  const handleSaveStatement = (e) => {
    e.preventDefault();
    onProblemStatementChange(problemStatement);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <form className="space-y-4">
      {['what', 'where', 'when', 'who', 'how', 'why'].map((field) => (
        <div key={field}>
          <label htmlFor={field} className="block text-sm font-medium text-gray-700 capitalize">
            {field}
          </label>
          <input
            type="text"
            id={field}
            name={field}
            value={problemStatement[field] || ''}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
          />
        </div>
      ))}

      <EnhancedTextArea
        label="Full Problem Statement"
        value={problemStatement.full_statement || ''}
        onChange={handleInputChange}
        name="full_statement"
        rows={4}
        onAIComment={handleAIComment}
      />

      <div>
        <label htmlFor="short_description" className="block text-sm font-medium text-gray-700">
          Short Description
        </label>
        <input
          type="text"
          id="short_description"
          name="short_description"
          value={problemStatement.short_description || ''}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
        />
      </div>

      <div className="flex space-x-4">
        <button
          onClick={handleGenerateStatement}
          className="flex-1 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Generate Problem Statement
        </button>
        <button
          onClick={handleSaveStatement}
          className="flex-1 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
        >
          Save Problem Statement
        </button>
      </div>
    </form>
  );
};

export default ProblemStatementBuilder;