import React, { useState, useEffect, useRef } from 'react';
import { ArrowRight, RefreshCw, Save, PlusCircle, XCircle, Edit2, Download } from 'lucide-react';
import { supabase } from '../supabaseClient';
import FishboneDiagram from './FishboneDiagram';
import { toPng } from 'html-to-image';

const FishboneDiagramTool = ({ projectId }) => {
  const predefinedCategories = {
    '4M': ['Methods', 'Materials', 'Machinery', 'People'],
    '4P': ['Policies', 'Procedures', 'People', 'Plant'],
    '6M': ['Machine', 'Method', 'Materials', 'Measurement', 'Man', 'Mother Nature'],
    'Other': ['Service', 'Quality', 'Technology', 'Consumables', 'Work Processes', 'Environment', 'Service Level']
  };

  const [shortDescription, setShortDescription] = useState('');
  const [causeClassifications, setCauseClassifications] = useState({
    root: [],
    contributing: [],
    possible: []
  });
  const [selectedPreset, setSelectedPreset] = useState('4M');
  const [categories, setCategories] = useState(predefinedCategories['4M']);
  const [causes, setCauses] = useState({});
  const [newCategory, setNewCategory] = useState('');
  const [editingCause, setEditingCause] = useState(null);
  const [editValue, setEditValue] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [saveStatus, setSaveStatus] = useState('');
  const [error, setError] = useState('');
  const [containerDimensions, setContainerDimensions] = useState({
    width: 1200,
    height: 400
  });

  const diagramRef = useRef(null);

  useEffect(() => {
    if (!projectId) {
      setError('No project ID provided');
      setIsLoading(false);
      return;
    }

    const fetchDiagram = async () => {
      setIsLoading(true);
      setError('');
      try {
        // First clean up any duplicate entries
        const { data: duplicates } = await supabase
          .from('fishbone_diagrams')
          .select('id')
          .eq('project_id', projectId);

        if (duplicates && duplicates.length > 1) {
          const sortedIds = duplicates
            .map(d => d.id)
            .sort()
            .slice(0, -1);

          await supabase
            .from('fishbone_diagrams')
            .delete()
            .in('id', sortedIds);
        }

        // Fetch problem statement
        const { data: problemData, error: problemError } = await supabase
          .from('problem_statements')
          .select('short_description')
          .eq('project_id', projectId)
          .maybeSingle();

        if (problemError) throw problemError;
        if (problemData) {
          setShortDescription(problemData.short_description || '');
        }

        // Fetch fishbone diagram data
        const { data, error: fishboneError } = await supabase
          .from('fishbone_diagrams')
          .select('*')
          .eq('project_id', projectId)
          .maybeSingle();

        if (fishboneError) throw fishboneError;

        if (data) {
          const diagramData = data.data || {
            cause_classifications: { root: [], contributing: [], possible: [] },
            selected_preset: '4M',
            categories: predefinedCategories['4M'],
            causes: {}
          };
          setCauseClassifications(diagramData.cause_classifications);
          setSelectedPreset(diagramData.selected_preset);
          setCategories(diagramData.categories);
          setCauses(diagramData.causes);
        }
      } catch (err) {
        console.error('Error fetching diagram:', err);
        setError('Failed to load diagram data. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchDiagram();
  }, [projectId]);

  useEffect(() => {
    const container = document.getElementById('fishbone-container');
    if (!container) return;

    const updateDimensions = () => {
      const width = container.clientWidth;
      const height = Math.max(300, width * 0.4);
      setContainerDimensions({ width, height });
    };

    const resizeObserver = new ResizeObserver(updateDimensions);
    resizeObserver.observe(container);
    updateDimensions();

    return () => resizeObserver.disconnect();
  }, []);

  const updateCauseClassification = (cause, classification) => {
    setCauseClassifications(prev => {
      // First remove the cause from all classifications
      const newClassifications = {
        root: prev.root.filter(c => c !== cause),
        contributing: prev.contributing.filter(c => c !== cause),
        possible: prev.possible.filter(c => c !== cause)
      };

      // Only add to new classification if one is selected (not "Unclassified")
      if (classification && classification !== 'unclassified') {
        newClassifications[classification] = [...newClassifications[classification], cause];
      }

      return newClassifications;
    });
  };

  const handlePresetChange = (preset) => {
    setSelectedPreset(preset);
    setCategories(predefinedCategories[preset]);
    setCauses({});
  };

  const addCategory = () => {
    if (newCategory.trim() && !categories.includes(newCategory.trim())) {
      setCategories(prev => [...prev, newCategory.trim()]);
      setCauses(prev => ({ ...prev, [newCategory.trim()]: [] }));
      setNewCategory('');
    }
  };

  const removeCategory = (category) => {
    setCategories(prev => prev.filter(c => c !== category));
    setCauses(prev => {
      const { [category]: _, ...rest } = prev;
      return rest;
    });
  };

  const addCause = (category, cause) => {
    if (cause.trim()) {
      setCauses(prev => ({
        ...prev,
        [category]: [...(prev[category] || []), cause.trim()]
      }));
    }
  };

  const removeCause = (category, index) => {
    const causeToRemove = causes[category][index];
    setCauses(prev => ({
      ...prev,
      [category]: prev[category].filter((_, i) => i !== index)
    }));
    setCauseClassifications(prev => ({
      root: prev.root.filter(c => c !== causeToRemove),
      contributing: prev.contributing.filter(c => c !== causeToRemove),
      possible: prev.possible.filter(c => c !== causeToRemove)
    }));
  };

  const saveDiagram = async () => {
    if (!projectId) {
      setError('No project ID provided');
      return;
    }

    setSaveStatus('Saving...');
    setError('');
    try {
      // Delete any existing entries first
      await supabase
        .from('fishbone_diagrams')
        .delete()
        .eq('project_id', projectId);

      // Insert new entry
      const { data, error: saveError } = await supabase
        .from('fishbone_diagrams')
        .insert({
          project_id: projectId,
          data: {
            cause_classifications: causeClassifications,
            selected_preset: selectedPreset,
            categories,
            causes
          }
        })
        .select()
        .single();

      if (saveError) throw saveError;

      setSaveStatus('Saved successfully');
      setTimeout(() => setSaveStatus(''), 3000);
    } catch (err) {
      console.error('Error saving diagram:', err);
      setError('Failed to save diagram. Please try again.');
      setSaveStatus('Error saving');
    }
  };

  const exportDiagram = async () => {
    if (!diagramRef.current) return;

    try {
      const dataUrl = await toPng(diagramRef.current, {
        backgroundColor: '#ffffff',
        pixelRatio: 2
      });
      
      const link = document.createElement('a');
      link.download = `fishbone-diagram-${new Date().toISOString().slice(0, 10)}.png`;
      link.href = dataUrl;
      link.click();
    } catch (err) {
      console.error('Error exporting diagram:', err);
      setError('Failed to export diagram. Please try again.');
    }
  };

  if (isLoading) {
    return <div className="text-center py-4">Loading Fishbone Diagram...</div>;
  }

  return (
    <div className="p-4 w-full">
      <h2 className="text-2xl font-bold mb-4">Fishbone Diagram Tool</h2>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
          {error}
        </div>
      )}

      <div className="mb-4">
        <h3 className="text-xl font-bold mb-2">Select Causal Areas:</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2">
          <select
            value={selectedPreset}
            onChange={(e) => handlePresetChange(e.target.value)}
            className="p-2 border rounded w-full"
          >
            {Object.keys(predefinedCategories).map(preset => (
              <option key={preset} value={preset}>{preset}</option>
            ))}
          </select>
          <input
            type="text"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            placeholder="Add custom category"
            className="p-2 border rounded w-full"
          />
          <button
            onClick={addCategory}
            className="p-2 bg-blue-500 text-white rounded hover:bg-blue-600 flex items-center justify-center"
          >
            <PlusCircle size={16} className="mr-2" />
            Add Category
          </button>
        </div>
      </div>

      <div className="mb-4">
        <h3 className="text-xl font-bold mb-2">Add Causes:</h3>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          {categories.map(category => (
            <div key={category} className="flex flex-col sm:flex-row items-start sm:items-center gap-2">
              <button
                onClick={() => removeCategory(category)}
                className="p-2 bg-red-500 text-white rounded hover:bg-red-600"
              >
                <XCircle size={16} />
              </button>
              <span className="font-bold whitespace-nowrap">{category}:</span>
              <div className="flex-grow flex gap-2 w-full">
                <input
                  type="text"
                  placeholder={`Add cause for ${category}`}
                  className="p-2 border rounded flex-grow min-w-0"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      addCause(category, e.target.value);
                      e.target.value = '';
                    }
                  }}
                />
                <button
                  onClick={(e) => {
                    const input = e.target.previousElementSibling;
                    addCause(category, input.value);
                    input.value = '';
                  }}
                  className="p-2 bg-green-500 text-white rounded hover:bg-green-600"
                >
                  Add
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="mb-8 bg-gray-50 p-4 rounded-lg border border-gray-300">
        <h3 className="text-xl font-bold mb-4">Fishbone Diagram</h3>
        <div id="fishbone-container" ref={diagramRef} className="bg-white border border-gray-200 rounded-lg shadow-sm" style={{ height: containerDimensions.height }}>
          <FishboneDiagram
            categories={categories}
            causes={causes}
            shortDescription={shortDescription}
            causeClassifications={causeClassifications}
            containerWidth={containerDimensions.width}
            containerHeight={containerDimensions.height}
          />
        </div>
      </div>

      <div className="mt-4">
        <h3 className="text-xl font-bold mb-4">Causes Summary:</h3>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          {categories.map(category => (
            <div key={category} className="bg-white p-4 rounded-lg shadow border border-gray-200">
              <h4 className="font-bold mb-2">{category}</h4>
              <ul className="space-y-2">
                {causes[category]?.map((cause, index) => (
                  <li key={index} className="flex items-center justify-between gap-2">
                    <span className="flex-grow">{cause}</span>
                    <select
                      value={
                        causeClassifications.root.includes(cause) ? 'root' :
                        causeClassifications.contributing.includes(cause) ? 'contributing' :
                        causeClassifications.possible.includes(cause) ? 'possible' :
                        'unclassified'
                      }
                      onChange={(e) => updateCauseClassification(cause, e.target.value)}
                      className="p-1 border rounded text-sm"
                    >
                      <option value="unclassified">Unclassified</option>
                      <option value="root">Root Cause</option>
                      <option value="contributing">Contributing Cause</option>
                      <option value="possible">Possible Cause</option>
                    </select>
                    <button
                      onClick={() => removeCause(category, index)}
                      className="p-1 text-red-500 hover:text-red-700"
                    >
                      <XCircle size={16} />
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>

      <div className="mt-4 flex gap-2">
        <button
          onClick={saveDiagram}
          className="p-2 bg-green-500 text-white rounded hover:bg-green-600 flex items-center"
        >
          <Save className="mr-2" size={16} />
          Save
        </button>
        <button
          onClick={exportDiagram}
          className="p-2 bg-blue-500 text-white rounded hover:bg-blue-600 flex items-center"
        >
          <Download className="mr-2" size={16} />
          Export as PNG
        </button>
      </div>

      {saveStatus && (
        <div className={`mt-2 text-center ${
          saveStatus.includes('Error') ? 'text-red-500' : 'text-green-500'
        }`}>
          {saveStatus}
        </div>
      )}
    </div>
  );
};

export default FishboneDiagramTool;