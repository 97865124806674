import React, { useState, useEffect, useCallback } from 'react';
import { supabase } from '../supabaseClient';

const ProjectList = ({ onProjectSelect, onFetchError }) => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchProjects = useCallback(async (retryCount = 0) => {
    try {
      setLoading(true);
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('User not authenticated');

      let query = supabase
        .from('projects')
        .select('*')
        .order('created_at', { ascending: false });

      // Check if user_id column exists
      const { data: columnInfo, error: columnError } = await supabase
        .from('projects')
        .select('user_id')
        .limit(1);

      if (!columnError && columnInfo.length > 0 && 'user_id' in columnInfo[0]) {
        // If user_id column exists, filter by it
        query = query.eq('user_id', user.id);
      } else {
        console.warn('user_id column not found in projects table. Fetching all projects.');
      }

      const { data, error } = await query;

      if (error) throw error;

      setProjects(data);
      onFetchError(''); // Clear any previous fetch errors
    } catch (error) {
      console.error('Error fetching projects:', error);
      if (retryCount < 3) {
        console.log(`Retrying fetch... Attempt ${retryCount + 1}`);
        setTimeout(() => fetchProjects(retryCount + 1), 1000); // Retry after 1 second
      } else {
        onFetchError('Error loading projects. Please refresh the page.');
      }
    } finally {
      setLoading(false);
    }
  }, [onFetchError]);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  if (loading) {
    return <div className="text-center py-4">Loading projects...</div>;
  }

  return (
    <div className="mt-8">
      <h2 className="text-2xl font-bold mb-4 text-blue-600">Existing Projects</h2>
      {projects.length === 0 ? (
        <p className="text-gray-600">No projects found. Create a new project to get started.</p>
      ) : (
        <div className="space-y-4">
          {projects.map(project => (
            <div 
              key={project.id} 
              onClick={() => onProjectSelect(project)} 
              className="cursor-pointer hover:bg-gray-100 p-4 border border-gray-200 rounded-md transition duration-300 ease-in-out"
            >
              <div className="font-semibold text-lg">{project.project_name}</div>
              <div className="text-sm text-gray-600 mt-1">
                Team: {Array.isArray(project.team_members) ? project.team_members.join(', ') : project.team_members}
              </div>
              <div className="text-xs text-gray-500 mt-1">
                ID: {project.project_id}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProjectList;