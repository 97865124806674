import React from 'react';
import RootCauseAnalysisPro from './components/RootCauseAnalysisPro';
import { AssistantProvider } from './contexts/AssistantContext';
import './App.css';

function App() {
  return (
    <AssistantProvider>
      <div className="App">
        <RootCauseAnalysisPro />
      </div>
    </AssistantProvider>
  );
}

export default App;