import React, { useState, useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';

// Register the annotation plugin
Chart.register(annotationPlugin);

const ChartGeneratorTool = () => {
  const [chartType, setChartType] = useState('Pareto Chart');
  const [chartTitle, setChartTitle] = useState('');
  const [data, setData] = useState([{ label: '', value: '' }]);
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  const handleAddRow = () => {
    setData([...data, { label: '', value: '' }]);
  };

  const handleRemoveRow = (index) => {
    const newData = data.filter((_, i) => i !== index);
    setData(newData);
  };

  const handleDataChange = (index, field, value) => {
    const newData = [...data];
    newData[index][field] = field === 'value' ? (value === '' ? '' : Number(value)) : value;
    setData(newData);
  };

  const generateParetoData = (inputData) => {
    // Filter out incomplete entries
    const validData = inputData.filter(item => item.label && item.value !== '');
    
    // Sort data by value in descending order
    const sortedData = [...validData].sort((a, b) => b.value - a.value);
    
    // Calculate cumulative percentage
    const total = sortedData.reduce((sum, item) => sum + item.value, 0);
    let cumulative = 0;
    const paretoLine = sortedData.map(item => {
      cumulative += item.value;
      return (cumulative / total) * 100;
    });

    return {
      labels: sortedData.map(item => item.label),
      values: sortedData.map(item => item.value),
      cumulative: paretoLine
    };
  };

  const generateHistogramData = (inputData) => {
    // Filter out incomplete entries
    const validData = inputData.filter(item => item.label && item.value !== '');
    
    // Create frequency map for each label
    const frequencyMap = new Map();
    validData.forEach(item => {
      const count = frequencyMap.get(item.label) || 0;
      frequencyMap.set(item.label, count + item.value);
    });

    // Sort by frequency in descending order
    const sortedEntries = Array.from(frequencyMap.entries())
      .sort((a, b) => b[1] - a[1]);

    return {
      labels: sortedEntries.map(([label]) => label),
      values: sortedEntries.map(([_, value]) => value)
    };
  };

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    if (!chartRef.current) return;

    const ctx = chartRef.current.getContext('2d');
    let chartData;
    let config;

    // Only create chart if we have valid data
    const validData = data.filter(item => item.label && item.value !== '');
    if (validData.length === 0) return;

    if (chartType === 'Pareto Chart') {
      const paretoData = generateParetoData(data);
      config = {
        type: 'bar',
        data: {
          labels: paretoData.labels,
          datasets: [
            {
              type: 'bar',
              label: 'Value',
              data: paretoData.values,
              backgroundColor: 'rgba(54, 162, 235, 0.5)',
              borderColor: 'rgba(54, 162, 235, 1)',
              borderWidth: 1,
              order: 1
            },
            {
              type: 'line',
              label: 'Cumulative %',
              data: paretoData.cumulative,
              borderColor: 'rgba(255, 99, 132, 1)',
              borderWidth: 2,
              fill: false,
              yAxisID: 'percentage',
              order: 0,
              pointRadius: 4,
              pointBackgroundColor: 'rgba(255, 99, 132, 1)'
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'Value'
              },
              grid: {
                display: false
              }
            },
            percentage: {
              position: 'right',
              beginAtZero: true,
              max: 100,
              title: {
                display: true,
                text: 'Cumulative %'
              },
              grid: {
                display: false
              }
            },
            x: {
              grid: {
                display: false
              }
            }
          },
          plugins: {
            title: {
              display: true,
              text: chartTitle || 'Pareto Chart'
            },
            annotation: {
              annotations: {
                eightyPercentLine: {
                  type: 'line',
                  yMin: 80,
                  yMax: 80,
                  yScaleID: 'percentage',
                  borderColor: 'rgba(0, 0, 0, 0.5)',
                  borderWidth: 1,
                  borderDash: [5, 5],
                  drawTime: 'beforeDatasetsDraw'
                }
              }
            }
          }
        }
      };
    } else { // Histogram
      const histogramData = generateHistogramData(data);
      config = {
        type: 'bar',
        data: {
          labels: histogramData.labels,
          datasets: [{
            label: 'Frequency',
            data: histogramData.values,
            backgroundColor: 'rgba(54, 162, 235, 0.5)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'Frequency'
              },
              grid: {
                display: false
              }
            },
            x: {
              title: {
                display: true,
                text: 'Components/Issues'
              },
              grid: {
                display: false
              }
            }
          },
          plugins: {
            title: {
              display: true,
              text: chartTitle || 'Histogram'
            },
            legend: {
              display: false
            }
          }
        }
      };
    }

    chartInstance.current = new Chart(ctx, config);

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data, chartType, chartTitle]);

  return (
    <div className="p-6 bg-white rounded-lg shadow">
      <h2 className="text-2xl font-bold mb-6">Chart Generator Tool</h2>
      
      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Chart Type:
        </label>
        <select
          value={chartType}
          onChange={(e) => setChartType(e.target.value)}
          className="w-full p-2 border rounded-md"
        >
          <option value="Pareto Chart">Pareto Chart</option>
          <option value="Histogram">Histogram</option>
        </select>
      </div>

      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Chart Title:
        </label>
        <input
          type="text"
          value={chartTitle}
          onChange={(e) => setChartTitle(e.target.value)}
          placeholder="Enter chart title"
          className="w-full p-2 border rounded-md"
        />
      </div>

      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-4">Enter Data</h3>
        <div className="grid grid-cols-12 gap-4 mb-2 font-medium">
          <div className="col-span-5">Label</div>
          <div className="col-span-5">Value</div>
          <div className="col-span-2">Action</div>
        </div>
        
        {data.map((row, index) => (
          <div key={index} className="grid grid-cols-12 gap-4 mb-2">
            <input
              type="text"
              value={row.label}
              onChange={(e) => handleDataChange(index, 'label', e.target.value)}
              placeholder="Enter label"
              className="col-span-5 p-2 border rounded-md"
            />
            <input
              type="number"
              value={row.value}
              onChange={(e) => handleDataChange(index, 'value', e.target.value)}
              placeholder="Enter value"
              className="col-span-5 p-2 border rounded-md"
            />
            <button
              onClick={() => handleRemoveRow(index)}
              className="col-span-2 text-red-500 hover:text-red-700"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
              </svg>
            </button>
          </div>
        ))}

        <button
          onClick={handleAddRow}
          className="mt-4 inline-flex items-center px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
          </svg>
          Add Row
        </button>
      </div>

      <div className="mt-6" style={{ height: '400px' }}>
        <canvas ref={chartRef}></canvas>
      </div>
    </div>
  );
};

export default ChartGeneratorTool;
