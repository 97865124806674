import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { Download } from 'lucide-react';

const ReportGenerator = ({ projectId }) => {
  const [projectData, setProjectData] = useState(null);
  const [problemStatement, setProblemStatement] = useState(null);
  const [correctiveActions, setCorrectiveActions] = useState([]);
  const [reviewData, setReviewData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReportData = async () => {
      if (!projectId) return;

      try {
        setLoading(true);
        setError(null);

        // Fetch project details
        const { data: project } = await supabase
          .from('projects')
          .select('*')
          .eq('id', projectId)
          .single();

        setProjectData(project);

        // Fetch problem statement
        const { data: problem } = await supabase
          .from('problem_statements')
          .select('*')
          .eq('project_id', projectId)
          .single();

        setProblemStatement(problem);

        // Fetch corrective actions
        const { data: actions } = await supabase
          .from('corrective_actions')
          .select('*')
          .eq('project_id', projectId);

        setCorrectiveActions(actions || []);

        // Fetch review data
        const { data: review } = await supabase
          .from('corrective_actions_review')
          .select('*')
          .eq('project_id', projectId)
          .single();

        setReviewData(review);
      } catch (err) {
        console.error('Error fetching report data:', err);
        setError('Failed to load report data');
      } finally {
        setLoading(false);
      }
    };

    fetchReportData();
  }, [projectId]);

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  if (loading) {
    return <div className="text-center py-4">Loading report...</div>;
  }

  if (error) {
    return <div className="text-red-500 text-center py-4">{error}</div>;
  }

  if (!projectData) {
    return <div className="text-center py-4">No project data available</div>;
  }

  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-blue-600">Root Cause Analysis Report</h2>
        <button
          className="flex items-center px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          onClick={() => window.print()}
        >
          <Download className="mr-2" size={16} />
          Download Report
        </button>
      </div>

      {/* Project Information */}
      <section className="border-b pb-6 mb-6">
        <h3 className="text-xl font-bold mb-4">Project Information</h3>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="font-semibold">Project Name:</p>
            <p>{projectData.project_name}</p>
          </div>
          <div>
            <p className="font-semibold">Project ID:</p>
            <p>{projectData.project_id}</p>
          </div>
          <div>
            <p className="font-semibold">Team Members:</p>
            <p>{Array.isArray(projectData.team_members) ? projectData.team_members.join(', ') : projectData.team_members}</p>
          </div>
          <div>
            <p className="font-semibold">Date Created:</p>
            <p>{formatDate(projectData.created_at)}</p>
          </div>
        </div>
      </section>

      {/* Problem Statement */}
      <section className="border-b pb-6 mb-6">
        <h3 className="text-xl font-bold mb-4">Problem Statement</h3>
        <p className="text-gray-700">{problemStatement?.full_statement || 'No problem statement available'}</p>
      </section>

      {/* Corrective Actions */}
      <section className="border-b pb-6 mb-6">
        <h3 className="text-xl font-bold mb-4">Corrective Action Plan</h3>
        <div className="overflow-x-auto">
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-gray-50">
                <th className="border p-2 text-left">Causal Factor</th>
                <th className="border p-2 text-left">Cause Description</th>
                <th className="border p-2 text-left">Root Cause Code</th>
                <th className="border p-2 text-left">Corrective Actions</th>
                <th className="border p-2 text-left">Person Responsible</th>
                <th className="border p-2 text-left">Due Date</th>
              </tr>
            </thead>
            <tbody>
              {correctiveActions.length > 0 ? (
                correctiveActions.map((action, index) => (
                  <tr key={index}>
                    <td className="border p-2">{action.type}</td>
                    <td className="border p-2">{action.finding_description}</td>
                    <td className="border p-2">{action.classification}</td>
                    <td className="border p-2">{action.corrective_action}</td>
                    <td className="border p-2">{action.responsible_person}</td>
                    <td className="border p-2">{formatDate(action.target_date)}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="border p-2 text-center text-gray-500">
                    No corrective actions defined
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </section>

      {/* Review and Approval */}
      {reviewData && (
        <section>
          <h3 className="text-xl font-bold mb-4">Review and Approval</h3>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="font-semibold">Prepared By:</p>
              <p>{reviewData.prepared_by}</p>
              <p className="text-sm text-gray-500">{formatDate(reviewData.prepared_date)}</p>
            </div>
            <div>
              <p className="font-semibold">Approved By:</p>
              <p>{reviewData.approved_by}</p>
              <p className="text-sm text-gray-500">{formatDate(reviewData.approved_date)}</p>
            </div>
            {reviewData.comments && (
              <div className="col-span-2">
                <p className="font-semibold">Comments:</p>
                <p className="text-gray-700">{reviewData.comments}</p>
              </div>
            )}
          </div>
        </section>
      )}
    </div>
  );
};

export default ReportGenerator;