import React, { useState, useEffect } from 'react';
import { PlusCircle, Save, RefreshCw, XCircle } from 'lucide-react';
import { supabase } from '../supabaseClient';

const FiveWhyAnalysisTool = ({ projectId }) => {
  const [whys, setWhys] = useState([
    { because: '', isRoot: false, contributing: '', possible: '' }
  ]);
  const [isLoading, setIsLoading] = useState(true);
  const [saveStatus, setSaveStatus] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (projectId) {
      fetchAnalysis();
    }
  }, [projectId]);

  const fetchAnalysis = async () => {
    setIsLoading(true);
    setError('');
    try {
      // First delete any duplicate entries if they exist
      const { data: duplicates } = await supabase
        .from('five_whys_analysis')
        .select('id')
        .eq('project_id', projectId);

      if (duplicates && duplicates.length > 1) {
        // Keep the most recent entry and delete others
        const sortedIds = duplicates
          .map(d => d.id)
          .sort()
          .slice(0, -1);

        await supabase
          .from('five_whys_analysis')
          .delete()
          .in('id', sortedIds);
      }

      // Now fetch the single remaining entry
      const { data, error } = await supabase
        .from('five_whys_analysis')
        .select('*')
        .eq('project_id', projectId)
        .maybeSingle();

      if (error) throw error;

      if (data) {
        setWhys(data.whys || [{ because: '', isRoot: false, contributing: '', possible: '' }]);
      }
    } catch (error) {
      console.error('Error fetching analysis:', error);
      setError('Failed to load analysis. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const addWhy = () => {
    if (whys.length < 10) {
      setWhys([...whys, { because: '', isRoot: false, contributing: '', possible: '' }]);
    }
  };

  const removeWhy = (index) => {
    const updatedWhys = whys.filter((_, i) => i !== index);
    setWhys(updatedWhys);
  };

  const updateWhy = (index, field, value) => {
    const updatedWhys = whys.map((why, i) => 
      i === index ? { ...why, [field]: value } : why
    );
    setWhys(updatedWhys);
  };

  const resetAnalysis = () => {
    setWhys([{ because: '', isRoot: false, contributing: '', possible: '' }]);
  };

  const saveAnalysis = async () => {
    setSaveStatus('Saving...');
    setError('');
    try {
      // First delete any existing entries
      await supabase
        .from('five_whys_analysis')
        .delete()
        .eq('project_id', projectId);

      // Then insert the new entry
      const { error: insertError } = await supabase
        .from('five_whys_analysis')
        .insert({
          project_id: projectId,
          whys: whys
        });

      if (insertError) throw insertError;

      setSaveStatus('Saved successfully');
      setTimeout(() => setSaveStatus(''), 3000);
    } catch (error) {
      console.error('Error saving analysis:', error);
      setError('Failed to save analysis. Please try again.');
      setSaveStatus('Error saving');
    }
  };

  if (isLoading) {
    return <div className="text-center py-4">Loading...</div>;
  }

  return (
    <div className="p-4 max-w-full mx-auto">
      <h1 className="text-3xl font-bold text-center text-blue-600 mb-4">The Five Whys</h1>
      
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
          {error}
        </div>
      )}

      <div className="bg-white p-4 mb-4 rounded-lg shadow overflow-x-auto">
        <table className="w-full min-w-max">
          <thead>
            <tr className="bg-gray-100">
              <th className="p-2 text-left">Step</th>
              <th className="p-2 text-left">This happened because:</th>
              <th className="p-2 text-left">Is this the root cause?</th>
              <th className="p-2 text-left">Contributing Causes</th>
              <th className="p-2 text-left">Possible Causes</th>
              <th className="p-2 text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            {whys.map((why, index) => (
              <tr key={index} className="border-b">
                <td className="p-2">Why {index + 1}</td>
                <td className="p-2">
                  <input
                    type="text"
                    value={why.because}
                    onChange={(e) => updateWhy(index, 'because', e.target.value)}
                    className="w-full p-2 border rounded"
                  />
                </td>
                <td className="p-2">
                  <select
                    value={why.isRoot ? 'Yes' : 'No'}
                    onChange={(e) => updateWhy(index, 'isRoot', e.target.value === 'Yes')}
                    className="w-full p-2 border rounded"
                  >
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </select>
                </td>
                <td className="p-2">
                  <input
                    type="text"
                    value={why.contributing}
                    onChange={(e) => updateWhy(index, 'contributing', e.target.value)}
                    className="w-full p-2 border rounded"
                    disabled={!why.isRoot}
                  />
                </td>
                <td className="p-2">
                  <input
                    type="text"
                    value={why.possible}
                    onChange={(e) => updateWhy(index, 'possible', e.target.value)}
                    className="w-full p-2 border rounded"
                    disabled={!why.isRoot}
                  />
                </td>
                <td className="p-2">
                  <button
                    onClick={() => removeWhy(index)}
                    className="p-1 bg-red-500 text-white rounded hover:bg-red-600"
                    title="Remove this why"
                  >
                    <XCircle size={16} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {whys.length < 10 && (
          <button onClick={addWhy} className="mt-2 p-2 bg-blue-500 text-white rounded hover:bg-blue-600 flex items-center">
            <PlusCircle className="mr-2" size={16} />
            Add Why
          </button>
        )}
      </div>

      <div className="flex justify-end space-x-2">
        <button onClick={resetAnalysis} className="p-2 bg-gray-500 text-white rounded hover:bg-gray-600 flex items-center">
          <RefreshCw className="mr-2" size={16} />
          Reset
        </button>
        <button onClick={saveAnalysis} className="p-2 bg-green-500 text-white rounded hover:bg-green-600 flex items-center">
          <Save className="mr-2" size={16} />
          Save
        </button>
      </div>
      {saveStatus && (
        <div className={`mt-2 text-center ${saveStatus.includes('Error') ? 'text-red-500' : 'text-green-500'}`}>
          {saveStatus}
        </div>
      )}
    </div>
  );
};

export default FiveWhyAnalysisTool;