import React, { useState, useEffect, useCallback } from 'react';
import { supabase } from '../supabaseClient';
import ProblemStatementBuilder from './ProblemStatementBuilder';

const ProblemDefinition = ({ projectId }) => {
  const [problemStatement, setProblemStatement] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const fetchProblemStatement = useCallback(async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('problem_statements')
        .select('*')
        .eq('project_id', projectId)
        .limit(1);

      if (error) throw error;

      if (data && data.length > 0) {
        setProblemStatement(data[0]);
      } else {
        setProblemStatement({});
      }
      setError('');
    } catch (error) {
      console.error('Error fetching problem statement:', error);
      setError('Failed to load problem statement. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [projectId]);

  useEffect(() => {
    fetchProblemStatement();
  }, [fetchProblemStatement]);

  const handleProblemStatementChange = async (updatedStatement) => {
    try {
      setLoading(true);
      console.log('Attempting to update problem statement:', { ...updatedStatement, project_id: projectId });
      
      const { data, error } = await supabase
        .from('problem_statements')
        .upsert({ 
          ...updatedStatement,
          project_id: projectId
        })
        .select()
        .single();

      if (error) throw error;

      console.log('Problem statement updated successfully:', data);
      setProblemStatement(data);
      setError('');
    } catch (error) {
      console.error('Error updating problem statement:', error);
      console.error('Error details:', JSON.stringify(error, null, 2));
      if (error.code === '42501') {
        setError('Permission denied. Please make sure you are authenticated and have the necessary permissions.');
      } else {
        setError(`Failed to save problem statement. Error: ${error.message}`);
      }
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="text-center py-4">Loading problem definition...</div>;
  }

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-blue-600">Problem Definition</h2>
      {error && <p className="text-red-500">{error}</p>}
      <ProblemStatementBuilder
        initialStatement={problemStatement}
        onProblemStatementChange={handleProblemStatementChange}
      />
    </div>
  );
};

export default ProblemDefinition;