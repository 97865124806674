import OpenAI from 'openai';

// Validate environment variables
const validateConfig = () => {
  const requiredVars = {
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    assistantId: process.env.REACT_APP_OPENAI_ASSISTANT_ID
  };

  Object.entries(requiredVars).forEach(([key, value]) => {
    if (!value) {
      throw new Error(`Missing OpenAI ${key} in environment variables`);
    }
  });

  return requiredVars;
};

const { apiKey, assistantId } = validateConfig();

// Initialize OpenAI client with v2 beta header
const openai = new OpenAI({
  apiKey,
  dangerouslyAllowBrowser: true,
  defaultHeaders: {
    'OpenAI-Beta': 'assistants=v2'
  }
});

export const createThread = async () => {
  try {
    const thread = await openai.beta.threads.create();
    return thread;
  } catch (error) {
    console.error('Thread creation error:', error);
    throw new Error(`Failed to create thread: ${error.message}`);
  }
};

export const addMessage = async (threadId, content) => {
  try {
    const message = await openai.beta.threads.messages.create(threadId, {
      role: 'user',
      content: content
    });
    return message;
  } catch (error) {
    console.error('Message addition error:', error);
    throw new Error(`Failed to add message: ${error.message}`);
  }
};

export const runAssistant = async (threadId) => {
  try {
    const run = await openai.beta.threads.runs.create(threadId, {
      assistant_id: assistantId
    });
    return run;
  } catch (error) {
    console.error('Assistant run error:', error);
    throw new Error(`Failed to run assistant: ${error.message}`);
  }
};

export const getMessages = async (threadId) => {
  try {
    const messages = await openai.beta.threads.messages.list(threadId);
    return messages.data;
  } catch (error) {
    console.error('Message retrieval error:', error);
    throw new Error(`Failed to get messages: ${error.message}`);
  }
};

export const checkRunStatus = async (threadId, runId) => {
  try {
    const run = await openai.beta.threads.runs.retrieve(threadId, runId);
    return run;
  } catch (error) {
    console.error('Run status check error:', error);
    throw new Error(`Failed to check run status: ${error.message}`);
  }
};

export default openai;