import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { PlusCircle, Save, ChevronDown, ChevronUp, Plus, Trash2 } from 'lucide-react';
import GuidelinesForWritingCAs from './GuidelinesForWritingCAs';
import CausalFactorTable from './CausalFactorTable';
import ReviewApproval from './ReviewApproval';

const CorrectiveActionPlan = ({ projectId }) => {
  const [problemStatement, setProblemStatement] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [showGuidelines, setShowGuidelines] = useState(false);
  const [causalFactors, setCausalFactors] = useState({
    fiveWhys: null,
    fishbone: null
  });

  useEffect(() => {
    if (!projectId) {
      setIsLoading(false);
      return;
    }

    const fetchData = async () => {
      try {
        // Fetch problem statement
        const { data: problemData } = await supabase
          .from('problem_statements')
          .select('full_statement')
          .eq('project_id', projectId)
          .maybeSingle();

        setProblemStatement(problemData?.full_statement || '');

        if (problemData?.full_statement) {
          // Fetch Five Whys data
          const { data: whysData } = await supabase
            .from('five_whys_analysis')
            .select('whys')
            .eq('project_id', projectId)
            .maybeSingle();

          // Fetch Fishbone data
          const { data: fishboneData } = await supabase
            .from('fishbone_diagrams')
            .select('data')
            .eq('project_id', projectId)
            .maybeSingle();

          setCausalFactors({
            fiveWhys: whysData?.whys || null,
            fishbone: fishboneData?.data?.cause_classifications || null
          });
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [projectId]);

  if (isLoading) {
    return (
      <div className="space-y-6">
        <h2 className="text-2xl font-bold text-blue-600">Corrective Action Plan</h2>
        <div className="text-center py-4">Loading...</div>
      </div>
    );
  }

  if (!problemStatement) {
    return (
      <div className="space-y-6">
        <h2 className="text-2xl font-bold text-blue-600">Corrective Action Plan</h2>
        <div className="bg-white p-4 rounded-lg shadow">
          <p className="text-red-500">Problem not defined. Please complete the Problem Definition step first.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-blue-600">Corrective Action Plan</h2>
      
      <div className="bg-white p-4 rounded-lg shadow">
        <h3 className="text-lg font-semibold mb-2">Problem Statement:</h3>
        <p className="text-gray-700">{problemStatement}</p>
      </div>

      <div className="bg-white p-4 rounded-lg shadow">
        <button
          onClick={() => setShowGuidelines(!showGuidelines)}
          className="flex items-center justify-between w-full px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-lg transition-colors duration-200"
        >
          <span className="font-semibold">Guidelines for Writing Corrective Actions</span>
          {showGuidelines ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
        </button>
        {showGuidelines && (
          <div className="mt-4">
            <GuidelinesForWritingCAs />
          </div>
        )}
      </div>

      <CausalFactorTable 
        projectId={projectId}
        causalFactors={causalFactors}
      />

      <ReviewApproval projectId={projectId} />
    </div>
  );
};

export default CorrectiveActionPlan;