import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

// Validate environment variables
if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error(
    'Missing Supabase environment variables. Please check your .env file and ensure REACT_APP_SUPABASE_URL and REACT_APP_SUPABASE_ANON_KEY are set.'
  );
}

// Validate URL format
try {
  new URL(supabaseUrl);
} catch (error) {
  throw new Error(
    'Invalid Supabase URL format. Please check your REACT_APP_SUPABASE_URL environment variable.'
  );
}

// Create Supabase client with retry logic and timeout
export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    persistSession: true,
    autoRefreshToken: true,
  },
  global: {
    fetch: (...args) => {
      return fetch(...args).catch(error => {
        console.error('Supabase fetch error:', error);
        throw new Error('Failed to connect to Supabase. Please check your internet connection.');
      });
    }
  },
  realtime: {
    timeout: 20000,
    params: {
      eventsPerSecond: 10
    }
  }
});

// Connection health check function
export const checkSupabaseConnection = async () => {
  try {
    const startTime = Date.now();
    const timeout = 5000; // 5 second timeout

    const timeoutPromise = new Promise((_, reject) => {
      setTimeout(() => reject(new Error('Connection timeout')), timeout);
    });

    const connectionPromise = supabase.from('projects').select('id').limit(1);
    
    const { error } = await Promise.race([connectionPromise, timeoutPromise]);
    
    if (error) throw error;

    const latency = Date.now() - startTime;
    console.log(`Supabase connection successful (latency: ${latency}ms)`);
    return true;
  } catch (error) {
    console.error('Supabase connection error:', error);
    return false;
  }
};

// Add connection state listener
let connectionTimeout;
supabase.channel('system')
  .on('system', { event: '*' }, (payload) => {
    if (payload.event === 'disconnected') {
      connectionTimeout = setTimeout(async () => {
        const isConnected = await checkSupabaseConnection();
        if (!isConnected) {
          console.error('Supabase connection lost and reconnection failed');
        }
      }, 3000);
    }
    if (payload.event === 'connected') {
      if (connectionTimeout) {
        clearTimeout(connectionTimeout);
      }
      console.log('Supabase connection restored');
    }
  })
  .subscribe();

export default supabase;