import React, { useState, useEffect } from 'react';
import ProjectInitialization from './ProjectInitialization';
import ProjectList from './ProjectList';
import ProblemDefinition from './ProblemDefinition';
import RootCauseIdentification from './RootCauseIdentification';
import CorrectiveActionPlan from './CorrectiveActionPlan';
import ReportGenerator from './ReportGenerator';
import SignIn from './SignIn';
import Registration from './Registration';
import AIAssistant from './AIAssistant';
import { supabase, checkSupabaseConnection } from '../supabaseClient';

const Tab = ({ children, isActive, onClick, disabled }) => (
  <button
    className={`px-4 py-2 ${
      isActive 
        ? 'bg-blue-500 text-white' 
        : disabled 
          ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
          : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
    } rounded-t-lg transition duration-300 ease-in-out flex-grow text-sm md:text-base`}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </button>
);

const NoProjectMessage = () => (
  <div className="flex flex-col items-center justify-center p-8 bg-gray-50 rounded-lg border border-gray-200">
    <div className="text-lg text-gray-600 mb-4">Please create or select a project first</div>
    <div className="text-sm text-gray-500">Use the Project Initialization tab to get started</div>
  </div>
);

const RootCauseAnalysisPro = () => {
  const [activeTab, setActiveTab] = useState(() => {
    const savedTab = localStorage.getItem('activeTab');
    return savedTab || 'project-init';
  });

  const [project, setProject] = useState(() => {
    const savedProject = localStorage.getItem('currentProject');
    return savedProject ? JSON.parse(savedProject) : null;
  });

  const [pageContext, setPageContext] = useState({
    problemStatement: '',
    currentStep: '',
    formData: null
  });

  const [user, setUser] = useState(null);
  const [isSupabaseConnected, setIsSupabaseConnected] = useState(false);
  const [connectionError, setConnectionError] = useState('');
  const [showRegistration, setShowRegistration] = useState(false);
  const [showReport, setShowReport] = useState(false);

  const mainTabs = [
    { id: 'project-init', label: 'Project Initialization' },
    { id: 'problem-definition', label: 'Problem Definition' },
    { id: 'root-cause-identification', label: 'Root Cause Identification' },
    { id: 'corrective-action-plan', label: 'Corrective Action Plan' }
  ];

  useEffect(() => {
    localStorage.setItem('activeTab', activeTab);
  }, [activeTab]);

  useEffect(() => {
    if (project) {
      localStorage.setItem('currentProject', JSON.stringify(project));
    } else {
      localStorage.removeItem('currentProject');
    }
  }, [project]);

  useEffect(() => {
    const checkConnection = async () => {
      const isConnected = await checkSupabaseConnection();
      setIsSupabaseConnected(isConnected);
      if (!isConnected) {
        setConnectionError('Unable to connect to the database. Please check your internet connection and try again.');
      }
    };
    checkConnection();
  }, []);

  useEffect(() => {
    const fetchSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      const currentUser = session?.user || null;
      setUser(currentUser);

      if (!currentUser) {
        localStorage.removeItem('activeTab');
        localStorage.removeItem('currentProject');
        setProject(null);
        setActiveTab('project-init');
      }
    };
    fetchSession();

    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        const currentUser = session?.user || null;
        setUser(currentUser);

        if (event === 'SIGNED_OUT') {
          localStorage.removeItem('activeTab');
          localStorage.removeItem('currentProject');
          setProject(null);
          setActiveTab('project-init');
        }
      }
    );

    return () => {
      if (authListener && authListener.unsubscribe) {
        authListener.unsubscribe();
      }
    };
  }, []);

  const updatePageContext = (newContext) => {
    setPageContext(prev => ({
      ...prev,
      ...newContext
    }));
  };

  const handleProjectInit = (newProject) => {
    setProject(newProject);
    setActiveTab('problem-definition');
    updatePageContext({
      currentStep: 'problem-definition',
      formData: null,
      problemStatement: ''
    });
  };

  const handleProjectSelect = (selectedProject) => {
    setProject(selectedProject);
    setActiveTab('problem-definition');
    updatePageContext({
      currentStep: 'problem-definition',
      formData: null,
      problemStatement: ''
    });
  };

  const handleSignIn = (user) => {
    setUser(user);
    setActiveTab('project-init');
  };

  const handleRegister = (user) => {
    setUser(user);
    setActiveTab('project-init');
    setShowRegistration(false);
  };

  const handleSignOut = async () => {
    await supabase.auth.signOut();
    setUser(null);
    setProject(null);
    setActiveTab('project-init');
    localStorage.removeItem('activeTab');
    localStorage.removeItem('currentProject');
  };

  const handleTabChange = (tabId) => {
    if (tabId === 'project-init' || project) {
      setActiveTab(tabId);
    }
  };

  const renderContent = () => {
    if (!user) {
      return showRegistration ? (
        <Registration onRegister={handleRegister} onCancel={() => setShowRegistration(false)} />
      ) : (
        <SignIn onSignIn={handleSignIn} onRegisterClick={() => setShowRegistration(true)} />
      );
    }

    if (showReport && project) {
      return <ReportGenerator projectId={project.id} updatePageContext={updatePageContext} />;
    }

    if (!project && activeTab !== 'project-init') {
      return <NoProjectMessage />;
    }

    const commonProps = {
      updatePageContext: updatePageContext
    };

    switch (activeTab) {
      case 'project-init':
        return (
          <div className="space-y-8">
            <ProjectInitialization 
              onProjectInit={handleProjectInit}
              {...commonProps}
            />
            <ProjectList 
              onProjectSelect={handleProjectSelect}
              onFetchError={(error) => setConnectionError(error)}
              {...commonProps}
            />
          </div>
        );
      case 'problem-definition':
        return (
          <ProblemDefinition 
            projectId={project.id}
            {...commonProps}
          />
        );
      case 'root-cause-identification':
        return (
          <RootCauseIdentification 
            projectId={project.id}
            {...commonProps}
          />
        );
      case 'corrective-action-plan':
        return (
          <CorrectiveActionPlan 
            projectId={project.id}
            {...commonProps}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="container mx-auto px-4 relative min-h-screen pb-16 bg-gray-50">
      <header className="bg-white shadow-md py-6 mb-8">
        <div className="flex justify-between items-center mb-4">
          <div className="text-3xl font-bold text-blue-600">Root Cause Analysis Pro</div>
          <div className="flex items-center space-x-4">
            {user && project && (
              <button
                onClick={() => setShowReport(!showReport)}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300 ease-in-out"
              >
                {showReport ? 'Back to Editor' : 'Generate Report'}
              </button>
            )}
            {user && (
              <button
                onClick={handleSignOut}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition duration-300 ease-in-out"
              >
                Sign Out
              </button>
            )}
          </div>
        </div>
        {connectionError && (
          <div className="text-red-500 mb-4">
            {connectionError}
          </div>
        )}
        {user && (
          <div className="text-sm text-gray-600 mb-4">
            Logged in as: {user.email}
          </div>
        )}
        {project && (
          <div className="text-sm text-gray-600 mb-4">
            Project: {project.project_name} | ID: {project.project_id} | Team: {Array.isArray(project.team_members) ? project.team_members.join(', ') : project.team_members}
          </div>
        )}
        {user && !showReport && (
          <nav className="flex flex-wrap gap-1">
            {mainTabs.map((tab) => (
              <Tab
                key={tab.id}
                isActive={activeTab === tab.id}
                onClick={() => handleTabChange(tab.id)}
                disabled={!project && tab.id !== 'project-init'}
              >
                {tab.label}
              </Tab>
            ))}
          </nav>
        )}
      </header>
      
      <main className="bg-white border border-gray-200 rounded-lg shadow-lg p-8 mb-8">
        {isSupabaseConnected ? renderContent() : <p>Connecting to the database...</p>}
      </main>

      {user && project && !showReport && (
        <AIAssistant 
          activeTab={activeTab}
          pageContext={pageContext}
          project={project}
        />
      )}
    </div>
  );
};

export default RootCauseAnalysisPro;