import React, { createContext, useContext, useState, useCallback } from 'react';
import { createThread, addMessage, runAssistant, getMessages, checkRunStatus } from '../config/openai';

const AssistantContext = createContext();

export const useAssistant = () => {
  const context = useContext(AssistantContext);
  if (!context) {
    throw new Error('useAssistant must be used within an AssistantProvider');
  }
  return context;
};

export const AssistantProvider = ({ children }) => {
  const [threadId, setThreadId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentContext, setCurrentContext] = useState({
    activeTab: '',
    pageContext: null,
    project: null
  });

  const initializeThread = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);
      const thread = await createThread();
      setThreadId(thread.id);
      return thread.id;
    } catch (err) {
      setError('Failed to initialize chat');
      throw err;
    } finally {
      setIsLoading(false);
    }
  }, []);

  const updateContext = useCallback((newContext) => {
    setCurrentContext(prev => ({
      ...prev,
      ...newContext
    }));
  }, []);

  const buildContextualPrompt = useCallback((content) => {
    const { activeTab, pageContext, project } = currentContext;
    let contextualPrompt = `[Current Page: ${activeTab}]\n`;

    if (pageContext) {
      contextualPrompt += `[Page Context: ${JSON.stringify(pageContext)}]\n`;
    }

    if (project) {
      contextualPrompt += `[Project: ${JSON.stringify(project)}]\n`;
    }

    contextualPrompt += `\nUser Message: ${content}`;
    return contextualPrompt;
  }, [currentContext]);

  const addAssistantMessage = useCallback((content) => {
    setMessages(prev => [...prev, {
      id: Date.now(),
      role: 'assistant',
      content: [{ text: { value: content } }]
    }]);
  }, []);

  const sendMessage = useCallback(async (content) => {
    if (!content.trim()) {
      setError('Message cannot be empty');
      return;
    }

    try {
      setIsLoading(true);
      setError(null);

      let currentThreadId = threadId;
      if (!currentThreadId) {
        currentThreadId = await initializeThread();
      }

      const contextualContent = buildContextualPrompt(content);
      await addMessage(currentThreadId, contextualContent);
      
      const run = await runAssistant(currentThreadId);
      
      let runStatus = await checkRunStatus(currentThreadId, run.id);
      let attempts = 0;
      const maxAttempts = 30;

      while (runStatus.status !== 'completed' && attempts < maxAttempts) {
        if (runStatus.status === 'failed' || runStatus.status === 'cancelled') {
          throw new Error(`Assistant run ${runStatus.status}: ${runStatus.last_error?.message || 'Unknown error'}`);
        }
        await new Promise(resolve => setTimeout(resolve, 1000));
        runStatus = await checkRunStatus(currentThreadId, run.id);
        attempts++;
      }

      if (attempts >= maxAttempts) {
        throw new Error('Response timeout - please try again');
      }

      const updatedMessages = await getMessages(currentThreadId);
      setMessages(updatedMessages);
      return updatedMessages[0];
    } catch (err) {
      const errorMessage = err.message || 'Failed to process message';
      setError(errorMessage);
      throw err;
    } finally {
      setIsLoading(false);
    }
  }, [threadId, initializeThread, buildContextualPrompt]);

  const clearThread = useCallback(async () => {
    try {
      setThreadId(null);
      setMessages([]);
      const newThreadId = await initializeThread();
      return newThreadId;
    } catch (err) {
      setError('Failed to clear chat');
      throw err;
    }
  }, [initializeThread]);

  const value = {
    threadId,
    messages,
    isLoading,
    error,
    sendMessage,
    clearThread,
    initializeThread,
    updateContext,
    currentContext,
    addAssistantMessage
  };

  return (
    <AssistantContext.Provider value={value}>
      {children}
    </AssistantContext.Provider>
  );
};

export default AssistantContext;