import React, { useState, useEffect } from 'react';
import { Plus, Trash2 } from 'lucide-react';
import { supabase } from '../supabaseClient';
import { v4 as uuidv4 } from 'uuid';

const CausalFactorTable = ({ projectId, causalFactors }) => {
  const [rows, setRows] = useState([]);
  const [rootCauseCodes, setRootCauseCodes] = useState([]);
  const [error, setError] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [saveStatus, setSaveStatus] = useState('');

  const validateActions = (actions) => {
    const errors = [];
    actions.forEach((action, index) => {
      // Only validate if any field in the action is filled out
      if (action.action.trim() || action.responsible.trim() || action.dueDate) {
        const missingFields = [];
        if (!action.action.trim()) missingFields.push('description');
        if (!action.responsible.trim()) missingFields.push('responsible person');
        if (!action.dueDate) missingFields.push('due date');
        
        if (missingFields.length > 0) {
          errors.push(`Action ${index + 1} is missing: ${missingFields.join(', ')}`);
        }
      }
    });
    return errors;
  };

  const saveCorrectiveActions = async () => {
    if (!projectId) {
      setError('No project ID provided');
      return;
    }

    try {
      setIsSaving(true);
      setSaveStatus('Saving...');
      setError('');

      // Filter out actions where no fields are filled
      const validActions = rows.flatMap(row => 
        row.actions
          .filter(action => action.action.trim() || action.responsible.trim() || action.dueDate)
          .map(action => ({
            project_id: projectId,
            finding_description: row.description,
            root_cause: row.type,
            corrective_action: action.action.trim(),
            responsible_person: action.responsible.trim(),
            target_date: action.dueDate || null,
            status: action.status || 'Open'
          }))
      );

      const validationErrors = validateActions(rows.flatMap(row => row.actions));
      if (validationErrors.length > 0) {
        throw new Error(`Please fix the following errors:\n${validationErrors.join('\n')}`);
      }

      if (validActions.length > 0) {
        const { data: existingActions } = await supabase
          .from('corrective_actions')
          .select('id')
          .eq('project_id', projectId);

        if (existingActions?.length > 0) {
          const { error: deleteError } = await supabase
            .from('corrective_actions')
            .delete()
            .eq('project_id', projectId);

          if (deleteError) throw deleteError;
        }

        const { error: insertError } = await supabase
          .from('corrective_actions')
          .insert(validActions);

        if (insertError) throw insertError;
      }

      setSaveStatus('Saved successfully');
      setTimeout(() => setSaveStatus(''), 3000);
    } catch (err) {
      console.error('Error saving corrective actions:', err);
      setError(err.message || 'Failed to save corrective actions');
      setSaveStatus('Error saving');
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (causalFactors) {
      const combinedFactors = [];

      // Process Five Whys factors
      if (causalFactors.fiveWhys) {
        causalFactors.fiveWhys.forEach(why => {
          if (why.isRoot) {
            combinedFactors.push({
              id: uuidv4(),
              type: 'Root Cause',
              description: why.because,
              classification: '',
              actions: [{
                id: uuidv4(),
                action: '',
                responsible: '',
                dueDate: ''
              }]
            });
          }
          if (why.contributing) {
            combinedFactors.push({
              id: uuidv4(),
              type: 'Contributing Cause',
              description: why.contributing,
              classification: '',
              actions: [{
                id: uuidv4(),
                action: '',
                responsible: '',
                dueDate: ''
              }]
            });
          }
        });
      }

      // Process Fishbone factors
      if (causalFactors.fishbone) {
        Object.entries(causalFactors.fishbone).forEach(([type, causes]) => {
          causes.forEach(cause => {
            combinedFactors.push({
              id: uuidv4(),
              type: type === 'root' ? 'Root Cause' :
                    type === 'contributing' ? 'Contributing Cause' :
                    'Possible Cause',
              description: cause,
              classification: '',
              actions: [{
                id: uuidv4(),
                action: '',
                responsible: '',
                dueDate: ''
              }]
            });
          });
        });
      }

      setRows(combinedFactors);
    }
  }, [causalFactors]);

  useEffect(() => {
    const fetchRootCauseCodes = async () => {
      try {
        const { data, error } = await supabase
          .from('root_causes')
          .select('code, sub_category')
          .eq('project_id', projectId);

        if (error) throw error;
        setRootCauseCodes(data || []);
      } catch (err) {
        console.error('Error fetching root cause codes:', err);
        setError('Failed to load root cause classifications');
      }
    };

    if (projectId) {
      fetchRootCauseCodes();
    }
  }, [projectId]);

  const updateClassification = (rowId, value) => {
    setRows(prevRows => 
      prevRows.map(row =>
        row.id === rowId ? { ...row, classification: value } : row
      )
    );
  };

  const updateAction = (rowId, actionId, field, value) => {
    setRows(prevRows =>
      prevRows.map(row =>
        row.id === rowId
          ? {
              ...row,
              actions: row.actions.map(action =>
                action.id === actionId
                  ? { ...action, [field]: value }
                  : action
              )
            }
          : row
      )
    );
  };

  const addActionToRow = (rowId) => {
    setRows(prevRows =>
      prevRows.map(row =>
        row.id === rowId
          ? {
              ...row,
              actions: [
                ...row.actions,
                {
                  id: uuidv4(),
                  action: '',
                  responsible: '',
                  dueDate: ''
                }
              ]
            }
          : row
      )
    );
  };

  const removeAction = (rowId, actionId) => {
    setRows(prevRows =>
      prevRows.map(row =>
        row.id === rowId
          ? {
              ...row,
              actions: row.actions.filter(action => action.id !== actionId)
            }
          : row
      )
    );
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-xl font-bold">Corrective Actions</h3>
        <button
          onClick={saveCorrectiveActions}
          disabled={isSaving}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-gray-400"
        >
          {isSaving ? 'Saving...' : 'Save Actions'}
        </button>
      </div>

      {error && <div className="text-red-500 mb-4">{error}</div>}
      {saveStatus && (
        <div className={`mb-4 ${saveStatus.includes('Error') ? 'text-red-500' : 'text-green-500'}`}>
          {saveStatus}
        </div>
      )}

      <div className="overflow-x-auto">
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-gray-50">
              <th className="border p-2 text-left">Type</th>
              <th className="border p-2 text-left">Cause Description</th>
              <th className="border p-2 text-left">Classification</th>
              <th className="border p-2 text-left">Actions</th>
              <th className="border p-2 text-left">Person Responsible</th>
              <th className="border p-2 text-left">Due Date</th>
              <th className="border p-2 text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            {rows.map(row => (
              <React.Fragment key={row.id}>
                {row.actions.map((action, actionIndex) => (
                  <tr key={action.id}>
                    {actionIndex === 0 && (
                      <>
                        <td className="border p-2" rowSpan={row.actions.length}>
                          {row.type}
                        </td>
                        <td className="border p-2" rowSpan={row.actions.length}>
                          {row.description}
                        </td>
                        <td className="border p-2" rowSpan={row.actions.length}>
                          <select
                            value={row.classification}
                            onChange={(e) => updateClassification(row.id, e.target.value)}
                            className="w-full p-1 border rounded"
                          >
                            <option value="">Select classification</option>
                            {rootCauseCodes.map(code => (
                              <option key={code.code} value={code.code}>
                                {code.code} - {code.sub_category}
                              </option>
                            ))}
                          </select>
                        </td>
                      </>
                    )}
                    <td className="border p-2">
                      <input
                        type="text"
                        value={action.action}
                        onChange={(e) => updateAction(row.id, action.id, 'action', e.target.value)}
                        className="w-full p-1 border rounded"
                        placeholder="Enter action"
                      />
                    </td>
                    <td className="border p-2">
                      <input
                        type="text"
                        value={action.responsible}
                        onChange={(e) => updateAction(row.id, action.id, 'responsible', e.target.value)}
                        className="w-full p-1 border rounded"
                        placeholder="Enter responsible person"
                      />
                    </td>
                    <td className="border p-2">
                      <input
                        type="date"
                        value={action.dueDate}
                        onChange={(e) => updateAction(row.id, action.id, 'dueDate', e.target.value)}
                        className="w-full p-1 border rounded"
                      />
                    </td>
                    <td className="border p-2">
                      <div className="flex space-x-2">
                        {row.actions.length > 1 && (
                          <button
                            onClick={() => removeAction(row.id, action.id)}
                            className="text-red-500 hover:text-red-700"
                          >
                            <Trash2 size={16} />
                          </button>
                        )}
                        {actionIndex === row.actions.length - 1 && (
                          <button
                            onClick={() => addActionToRow(row.id)}
                            className="text-blue-500 hover:text-blue-700"
                          >
                            <Plus size={16} />
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CausalFactorTable;