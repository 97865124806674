import React, { useState } from 'react';
import { Sparkles } from 'lucide-react';
import OpenAI from 'openai';

// Initialize OpenAI client with v2 beta header
const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
  defaultHeaders: {
    'OpenAI-Beta': 'assistants=v2'
  }
});

const EnhancedTextArea = ({ 
  value, 
  onChange, 
  label,
  name = 'full_statement',
  placeholder = '',
  rows = 4,
  className = '',
  onAIComment
}) => {
  const [isEnhancing, setIsEnhancing] = useState(false);
  const [error, setError] = useState('');

  const enhanceText = async (e) => {
    e.preventDefault();
    
    if (!value.trim()) {
      setError('Please enter some text before enhancing');
      return;
    }

    try {
      setIsEnhancing(true);
      setError('');

      const thread = await openai.beta.threads.create();
      
      await openai.beta.threads.messages.create(thread.id, {
        role: 'user',
        content: `Please improve the structure and clarity of this problem statement. 
          IMPORTANT RULES:
          - Only use information explicitly provided in the original text
          - Do not add any new details or assumptions
          - Focus on improving clarity, grammar, and organization
          - Maintain all original facts and specifics exactly as provided
          
          Provide your response in two parts:
          1. ENHANCED_TEXT: The restructured problem statement
          2. COMMENTS: Brief explanation of the structural improvements made
          
          Original text: ${value}`
      });

      const run = await openai.beta.threads.runs.create(thread.id, {
        assistant_id: process.env.REACT_APP_OPENAI_ASSISTANT_ID,
        instructions: "You are helping to structure problem statements. Only use information explicitly provided by the user. Never add new details or make assumptions. Focus solely on improving clarity and organization of the existing content."
      });

      let runStatus = await openai.beta.threads.runs.retrieve(thread.id, run.id);
      while (runStatus.status !== 'completed') {
        await new Promise(resolve => setTimeout(resolve, 1000));
        runStatus = await openai.beta.threads.runs.retrieve(thread.id, run.id);
        
        if (runStatus.status === 'failed') {
          throw new Error('Enhancement failed');
        }
      }

      const messages = await openai.beta.threads.messages.list(thread.id);
      const response = messages.data[0].content[0].text.value;
      
      // Extract enhanced text and comments
      const enhancedMatch = response.match(/ENHANCED_TEXT:(.*?)(?=COMMENTS:|$)/s);
      const commentsMatch = response.match(/COMMENTS:(.*?)$/s);
      
      const enhancedText = enhancedMatch ? enhancedMatch[1].trim() : '';
      const comments = commentsMatch ? commentsMatch[1].trim() : '';

      if (!enhancedText) {
        throw new Error('Failed to generate enhanced text');
      }

      // Update the text field with enhanced text
      onChange({
        target: {
          name,
          value: enhancedText
        }
      });

      // Send comments to AI Assistant chat
      if (comments && onAIComment) {
        onAIComment(
          "Here are the structural improvements made to your problem statement:\n\n" + 
          comments
        );
      }

    } catch (err) {
      console.error('Error enhancing text:', err);
      setError('Failed to enhance text. Please try again.');
    } finally {
      setIsEnhancing(false);
    }
  };

  return (
    <div className="relative">
      {label && (
        <label className="block text-sm font-medium text-gray-700 mb-1">
          {label}
        </label>
      )}
      <div className="relative">
        <textarea
          value={value}
          onChange={onChange}
          name={name}
          rows={rows}
          className={`w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 pr-10 ${className}`}
          placeholder={placeholder}
        />
        <button
          onClick={enhanceText}
          disabled={isEnhancing}
          className={`absolute right-2 top-2 p-1 rounded-full hover:bg-gray-100 transition-colors ${
            isEnhancing ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          title="Enhance text with AI"
          type="button"
        >
          <Sparkles 
            size={20} 
            className={`text-blue-500 ${isEnhancing ? 'animate-pulse' : ''}`} 
          />
        </button>
      </div>
      {error && (
        <p className="mt-1 text-sm text-red-500">{error}</p>
      )}
    </div>
  );
};

export default EnhancedTextArea;