import React from 'react';

const FishboneDiagram = ({ 
  categories, 
  causes, 
  problem,
  shortDescription,
  causeClassifications,
  containerWidth = 1200,
  containerHeight = 400 
}) => {
  const margin = { top: 40, right: 40, bottom: 40, left: 40 };
  const width = containerWidth - margin.left - margin.right;
  const height = containerHeight - margin.top - margin.bottom;
  const centerY = height / 2;
  
  // Calculate dynamic spacing based on container width
  const spineLength = width * 0.8;
  const categorySpacing = spineLength / (categories.length + 1);
  const problemBoxWidth = Math.min(300, width * 0.25);
  const problemBoxHeight = Math.min(100, height * 0.2);
  const causeBoxWidth = Math.min(300, width * 0.25);
  const causeBoxHeight = Math.min(150, height * 0.25);
  
  // Dynamic font sizing based on container dimensions
  const categoryFontSize = Math.max(14, Math.min(18, width / 60)); // Increased base size for categories
  const causeFontSize = Math.max(12, Math.min(16, width / 70)); // Increased base size for causes
  const problemFontSize = Math.max(12, Math.min(16, width / 70)); // Consistent with causes

  return (
    <svg 
      width="100%" 
      height="100%" 
      viewBox={`0 0 ${containerWidth} ${containerHeight}`}
      preserveAspectRatio="xMidYMid meet"
      className="border border-gray-300 bg-white"
    >
      <g transform={`translate(${margin.left},${margin.top})`}>
        {/* Main spine */}
        <line 
          x1={0} 
          y1={centerY} 
          x2={width} 
          y2={centerY} 
          stroke="black" 
          strokeWidth="2" 
        />

        {/* Problem Description Box */}
        <g transform={`translate(${width - problemBoxWidth}, ${centerY - problemBoxHeight/2})`}>
          <rect 
            width={problemBoxWidth} 
            height={problemBoxHeight} 
            fill="white" 
            stroke="black" 
          />
          <foreignObject 
            width={problemBoxWidth - 10} 
            height={problemBoxHeight - 10} 
            x={5} 
            y={5}
          >
            <div className="text-center">
              <p className="font-bold" style={{ fontSize: `${problemFontSize}px` }}>Problem Description</p>
              <p className="mt-1" style={{ fontSize: `${problemFontSize - 2}px` }}>
                {shortDescription || "No problem description available"}
              </p>
            </div>
          </foreignObject>
        </g>

        {/* Cause Classifications Box */}
        <g transform={`translate(${width - causeBoxWidth}, ${centerY + problemBoxHeight/2 + 10})`}>
          <foreignObject 
            width={causeBoxWidth} 
            height={causeBoxHeight}
          >
            <div className="space-y-1" style={{ fontSize: `${problemFontSize - 2}px` }}>
              <p><strong>Root Causes:</strong> {causeClassifications.root.join(', ')}</p>
              <p><strong>Contributing:</strong> {causeClassifications.contributing.join(', ')}</p>
              <p><strong>Possible:</strong> {causeClassifications.possible.join(', ')}</p>
            </div>
          </foreignObject>
        </g>

        {/* Categories and Causes */}
        {categories.map((category, index) => {
          const x = (index + 1) * categorySpacing;
          const isUpper = index % 2 === 0;
          const yDirection = isUpper ? -1 : 1;
          const branchLength = Math.min(200, height * 0.3);
          
          return (
            <g key={category}>
              {/* Category branch */}
              <line
                x1={x}
                y1={centerY}
                x2={x}
                y2={centerY + yDirection * branchLength}
                stroke="black"
                strokeWidth="2"
              />
              
              {/* Category label */}
              <text
                x={x}
                y={centerY + yDirection * (branchLength + 20)}
                textAnchor="middle"
                dominantBaseline={isUpper ? "auto" : "hanging"}
                className="fill-blue-600 font-bold"
                style={{ fontSize: `${categoryFontSize}px` }}
              >
                {category}
              </text>

              {/* Causes */}
              {causes[category]?.map((cause, causeIndex) => {
                const yOffset = 25 + causeIndex * (causeFontSize + 6); // Increased spacing between causes
                return (
                  <text
                    key={causeIndex}
                    x={x + (isUpper ? 8 : -8)} // Increased offset from branch
                    y={centerY + yDirection * yOffset}
                    textAnchor={isUpper ? "start" : "end"}
                    dominantBaseline="middle"
                    className="fill-gray-700"
                    style={{ fontSize: `${causeFontSize}px` }}
                  >
                    {cause}
                  </text>
                );
              })}
            </g>
          );
        })}
      </g>
    </svg>
  );
};

export default FishboneDiagram;