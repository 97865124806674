import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import rootCauseData from '../data/RootCauseClass';

const RootCauseClassification = ({ projectId }) => {
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState('');
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [subcategories, setSubcategories] = useState([]);
  const [selectedRootCauses, setSelectedRootCauses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Verify project access
  const verifyProjectAccess = async () => {
    try {
      const { data, error } = await supabase
        .from('projects')
        .select('id')
        .eq('id', projectId)
        .single();

      if (error) throw error;
      if (!data) throw new Error('Project not found or access denied');

      return true;
    } catch (err) {
      console.error('Error verifying project access:', err);
      setError('Access to project denied');
      return false;
    }
  };

  // Load existing root causes for the project
  const loadProjectRootCauses = async () => {
    if (!projectId) {
      setError('No project ID provided');
      return;
    }

    try {
      setIsLoading(true);
      setError(null);

      // First verify project access
      const hasAccess = await verifyProjectAccess();
      if (!hasAccess) return;

      const { data, error: supabaseError } = await supabase
        .from('root_causes')
        .select('*')
        .eq('project_id', projectId);

      if (supabaseError) throw supabaseError;

      setSelectedRootCauses(data || []);
    } catch (err) {
      console.error('Error loading root causes:', err);
      setError('Failed to load root causes');
    } finally {
      setIsLoading(false);
    }
  };

  // Initial load of root causes
  useEffect(() => {
    if (projectId) {
      loadProjectRootCauses();
    }
  }, [projectId]);

  // Load areas (formerly investigation titles)
  useEffect(() => {
    const uniqueAreas = [...new Set(rootCauseData.query.map(item => item['Investigation Title']))];
    setAreas(uniqueAreas);
  }, []);

  // Update categories when area changes
  useEffect(() => {
    if (selectedArea) {
      const filteredCategories = [...new Set(rootCauseData.query
        .filter(item => item['Investigation Title'] === selectedArea)
        .map(item => item.Category))];
      setCategories(filteredCategories);
      setSelectedCategory('');
    } else {
      setCategories([]);
    }
  }, [selectedArea]);

  // Update subcategories when category changes
  useEffect(() => {
    if (selectedCategory) {
      const filteredSubcategories = rootCauseData.query.filter(
        item => item['Investigation Title'] === selectedArea && 
              item.Category === selectedCategory
      );
      setSubcategories(filteredSubcategories);
    } else {
      setSubcategories([]);
    }
  }, [selectedArea, selectedCategory]);

  const handleAreaChange = (e) => {
    setSelectedArea(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handleSubcategorySelect = async (subcategory) => {
    if (!projectId) {
      setError('No project ID provided');
      return;
    }

    try {
      // Check if already selected
      if (selectedRootCauses.some(item => item.code === subcategory.Code)) {
        return;
      }

      setIsLoading(true);
      setError(null);

      // First verify project access
      const hasAccess = await verifyProjectAccess();
      if (!hasAccess) return;

      const { error: insertError } = await supabase
        .from('root_causes')
        .insert([{
          project_id: projectId,
          investigation_title: subcategory['Investigation Title'],
          category: subcategory.Category,
          code: subcategory.Code,
          sub_category: subcategory['Sub-category']
        }]);

      if (insertError) throw insertError;

      // Reload root causes to get the updated list
      await loadProjectRootCauses();
    } catch (err) {
      console.error('Error adding root cause:', err);
      setError(err.message || 'Failed to add root cause');
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveRootCause = async (code) => {
    if (!projectId) {
      setError('No project ID provided');
      return;
    }

    try {
      setIsLoading(true);
      setError(null);

      // First verify project access
      const hasAccess = await verifyProjectAccess();
      if (!hasAccess) return;

      const { error: deleteError } = await supabase
        .from('root_causes')
        .delete()
        .eq('project_id', projectId)
        .eq('code', code);

      if (deleteError) throw deleteError;

      // Reload root causes to get the updated list
      await loadProjectRootCauses();
    } catch (err) {
      console.error('Error removing root cause:', err);
      setError(err.message || 'Failed to remove root cause');
    } finally {
      setIsLoading(false);
    }
  };

  if (!projectId) {
    return <div className="text-center py-4 text-red-500">No project ID provided</div>;
  }

  return (
    <div className="space-y-4">
      <h2 className="text-xl font-bold">Root Cause Classification</h2>
      
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
          {error}
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="space-y-4">
          <div>
            <label className="block mb-2 font-bold">Area:</label>
            <select 
              value={selectedArea} 
              onChange={handleAreaChange}
              className="w-full p-2 border rounded"
              disabled={isLoading}
            >
              <option value="">Select an area</option>
              {areas.map(area => (
                <option key={area} value={area}>{area}</option>
              ))}
            </select>
          </div>

          {selectedArea && (
            <div>
              <label className="block mb-2">Category:</label>
              <select 
                value={selectedCategory} 
                onChange={handleCategoryChange}
                className="w-full p-2 border rounded"
                disabled={isLoading}
              >
                <option value="">Select a category</option>
                {categories.map(category => (
                  <option key={category} value={category}>{category}</option>
                ))}
              </select>
            </div>
          )}

          {selectedCategory && (
            <div>
              <label className="block mb-2">Root Cause Code and Description:</label>
              <div className="max-h-96 overflow-y-auto border rounded">
                {subcategories.map(subcategory => (
                  <div 
                    key={subcategory.Code}
                    onClick={() => !isLoading && handleSubcategorySelect(subcategory)}
                    className={`p-2 hover:bg-blue-50 cursor-pointer border-b ${
                      selectedRootCauses.some(item => item.code === subcategory.Code)
                        ? 'bg-blue-100'
                        : ''
                    }`}
                  >
                    <div className="font-medium">{subcategory.Code}</div>
                    <div className="text-sm text-gray-600">{subcategory['Sub-category']}</div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div>
          <div className="mb-2 font-bold">Classifications:</div>
          {selectedRootCauses.length === 0 ? (
            <div className="text-gray-500 italic">No root causes selected</div>
          ) : (
            <div className="space-y-2">
              {selectedRootCauses.map(cause => (
                <div 
                  key={cause.code}
                  className="flex justify-between items-start p-3 bg-gray-50 rounded border"
                >
                  <div>
                    <div className="font-medium">{cause.code}</div>
                    <div className="text-sm text-gray-600">{cause.sub_category}</div>
                    <div className="text-xs text-gray-500 mt-1">
                      {cause.investigation_title} - {cause.category}
                    </div>
                  </div>
                  <button
                    onClick={() => !isLoading && handleRemoveRootCause(cause.code)}
                    className="text-red-500 hover:text-red-700"
                    disabled={isLoading}
                  >
                    Remove
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-4 rounded">Loading...</div>
        </div>
      )}
    </div>
  );
};

export default RootCauseClassification;