import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { Save } from 'lucide-react';

const ReviewApproval = ({ projectId }) => {
  const [reviewData, setReviewData] = useState({
    preparedBy: '',
    preparedDate: '',
    approvedBy: '',
    approvedDate: '',
    comments: ''
  });
  const [saveStatus, setSaveStatus] = useState('');

  useEffect(() => {
    const fetchReviewData = async () => {
      if (!projectId) return;

      try {
        // First clean up any duplicate entries
        const { data: duplicates } = await supabase
          .from('corrective_actions_review')
          .select('id')
          .eq('project_id', projectId);

        if (duplicates && duplicates.length > 1) {
          // Keep the most recent entry and delete others
          const sortedIds = duplicates
            .map(d => d.id)
            .sort()
            .slice(0, -1);

          await supabase
            .from('corrective_actions_review')
            .delete()
            .in('id', sortedIds);
        }

        // Now fetch the single remaining entry
        const { data, error } = await supabase
          .from('corrective_actions_review')
          .select('*')
          .eq('project_id', projectId)
          .maybeSingle();

        if (error && error.code !== 'PGRST116') {
          throw error;
        }

        if (data) {
          setReviewData({
            preparedBy: data.prepared_by || '',
            preparedDate: data.prepared_date || '',
            approvedBy: data.approved_by || '',
            approvedDate: data.approved_date || '',
            comments: data.comments || ''
          });
        }
      } catch (error) {
        console.error('Error fetching review data:', error);
      }
    };

    fetchReviewData();
  }, [projectId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setReviewData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSave = async () => {
    if (!projectId) return;

    setSaveStatus('Saving...');
    try {
      // First delete any existing entries
      await supabase
        .from('corrective_actions_review')
        .delete()
        .eq('project_id', projectId);

      // Then insert the new entry
      const { error } = await supabase
        .from('corrective_actions_review')
        .insert({
          project_id: projectId,
          prepared_by: reviewData.preparedBy,
          prepared_date: reviewData.preparedDate || null,
          approved_by: reviewData.approvedBy,
          approved_date: reviewData.approvedDate || null,
          comments: reviewData.comments
        });

      if (error) throw error;
      setSaveStatus('Saved successfully');
      setTimeout(() => setSaveStatus(''), 3000);
    } catch (error) {
      console.error('Error saving review data:', error);
      setSaveStatus('Error saving');
      setTimeout(() => setSaveStatus(''), 3000);
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow mt-6">
      <h3 className="text-xl font-bold mb-6">Review and Approval</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Prepared by:
          </label>
          <input
            type="text"
            name="preparedBy"
            value={reviewData.preparedBy}
            onChange={handleInputChange}
            className="w-full p-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Date:
          </label>
          <input
            type="date"
            name="preparedDate"
            value={reviewData.preparedDate}
            onChange={handleInputChange}
            className="w-full p-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Approved by:
          </label>
          <input
            type="text"
            name="approvedBy"
            value={reviewData.approvedBy}
            onChange={handleInputChange}
            className="w-full p-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Date:
          </label>
          <input
            type="date"
            name="approvedDate"
            value={reviewData.approvedDate}
            onChange={handleInputChange}
            className="w-full p-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
      </div>
      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Comments:
        </label>
        <textarea
          name="comments"
          value={reviewData.comments}
          onChange={handleInputChange}
          rows="4"
          className="w-full p-2 border rounded-md focus:ring-blue-500 focus:border-blue-500 resize-none"
        />
      </div>
      <div className="flex items-center">
        <button
          onClick={handleSave}
          className="inline-flex items-center px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <Save className="w-4 h-4 mr-2" />
          Save Review
        </button>
        {saveStatus && (
          <span className={`ml-4 ${saveStatus.includes('Error') ? 'text-red-500' : 'text-green-500'}`}>
            {saveStatus}
          </span>
        )}
      </div>
    </div>
  );
};

export default ReviewApproval;